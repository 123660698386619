export const allVehicles = {
  CHEVROLET: [
    "GENTRA",
    "COBALT",
    "LACETTI",
    "SPARK",
    "MATIZ",
    "NEXIA 1",
    "NEXIA 2",
    "NEXIA 3",
    "DAMAS",
    "ONIX",
    "EPICA",
    "MALIBU XL",
    "MALIBU",
    "TRACKER",
    "EQUINOX",
    "TRAVERSE",
    "TAHOE",
    "CAPTIVA",
  ],
  LADA: [
    "Granta Sedan",
    "Granta liftback",
    "Granta Universal",
    "Granta Cross",
    "Vesta Sedan",
    "Vesta Cross",
    "Vesta SW",
    "Vesta SW Cross",
    "Largus Universal",
    "Largus Cross",
    "Largus Cross",
    "Niva Legend",
  ],
  BYD: [
    "HAN",
    "CHAZOR",
    "SONG PLUS DM-i",
    "SONG PLUS DM-i CHAMPION",
    "SONG PLUS EV CHAMPION",
    "SONG PRO DM-i Champion",
    "Song L",
    "Song MAX New Energy",
    "Song Pro New Energy",
    "Song PLUS New Energy",
    "Qin PLUS",
    "​Tang New Energy",
    "Yuan Pro",
    "yuan PLUS",
    "Seagull",
    "e2",
    "Dolphin",
    "Escort 07",
    "Destroyer 05",
    "Seal",
    "Seal",
  ],
  KIA: [
    "K9",
    "EV6",
    "K5",
    "K8",
    "Sonet",
    "Sorento",
    "EV9",
    "Seltos",
    "Sportage",
    "Carnival",
  ],
  ZAZ: ["Forza"],
  JAC: ["M3", "M4", "J7", "JS6", "JS8"],
  ZEEKR: ["001"],
  CHERY: [
    "Arrizo 5",
    "Arrizo 6 Pro",
    "Arrizo 8",
    "TiGGO 7 PLUS",
    "TiGGO 7 PLUS new energy",
  ],
};
